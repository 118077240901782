/* eslint-disable arrow-body-style */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useConnectGoogleMarketplaceApp } from 'hooks/google-marketplace';
import { useAppContext } from 'AppContext';
import { useWorkspaceId } from 'hooks/workspace';
import { useWorkspaceContext } from 'context/WorkspaceContext';

// Define validation schema with zod
const emailSchema = z.object({
  email: z.string().email('Invalid email address').min(1, 'Email is required'),
});

const GoogleConnectDialog = forwardRef((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const { state: currentUser } = useAppContext();
  const { workspaceId } = useWorkspaceId();
  const userId = currentUser?.currentUser?.id;
  const [connectGoogleMarketplaceApp, { loading: connecting }] =
    useConnectGoogleMarketplaceApp();
  const {
    setIsGoogleWorkspaceConnected,
  } = useWorkspaceContext();

  const form = useForm({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: '',
    },
  });
  // Expose open and close functions to the parent using `useImperativeHandle`
  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => {
      form.reset();
      setIsOpen(false);
    },
  }));

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const handleDataOnSubmit = async (values) => {
    const { email } = values;
    if (userId) {
      const res = await connectGoogleMarketplaceApp({
        variables: {
          where: {
            email,
            workspaceId,
          },
        },
      });
      if (res?.data?.connectGoogleMarketplaceApp) {
        setIsGoogleWorkspaceConnected(true);
        form.reset();
        setIsOpen(false);
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="rounded-[6px] max-w-md">
        <Form {...form}>
          <form onSubmit={handleSubmit(handleDataOnSubmit)}>
            <DialogHeader>
              <DialogTitle>Connect to Google Workspace</DialogTitle>
            </DialogHeader>
            <div className="flex items-center space-x-2">
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Email Address</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        id="email"
                        placeholder="Enter Google Workspace Admin Email"
                        className="h-[30px]"
                      />
                    </FormControl>
                    <FormMessage>{errors.email?.message}</FormMessage>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-end gap-3 pt-4">
              <Button
                type="button"
                className="w-[max-content]"
                onClick={() => setIsOpen(false)}
                variant="outline"
              >
                Cancel
              </Button>
              <Button type="submit" disabled={connecting}>
                {connecting ? 'Connecting...' : 'Submit'}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

export default GoogleConnectDialog;
