/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import { useAppContext } from 'AppContext';
import { GoogleWorkspaceIcon } from 'assets/svg';
import Button from 'components/Button';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  useRemoveAccessToken,
} from 'hooks/google-marketplace';
import { useWorkspaceId } from 'hooks/workspace';

import React, { useRef } from 'react';
import GoogleConnectDialog from './GoogleConnectDialog';

const Integrations = () => {
  const { state: currentUser } = useAppContext();
  const {
    state: { isGoogleWorkspaceConnected },
    setIsGoogleWorkspaceConnected,
  } = useWorkspaceContext();
  const userId = currentUser?.currentUser?.id;

  const [removeAccessToken, { loading: removingToken }] =
    useRemoveAccessToken();

  const { workspaceId } = useWorkspaceId();

  const dialogRef = useRef(null);

  const handleConnectClick = () => {
    dialogRef.current?.open(); // Open the dialog when the user clicks Connect
  };
  // State to track connection status

  const handleDisconnectClick = async () => {
    if (userId) {
      // Call the mutation to remove the access token
      await removeAccessToken({
        variables: {
          where: {
            userId,
            workspaceId,
          },
        },
      });
      setIsGoogleWorkspaceConnected(false); // Update state to reflect the disconnection
    }
  };

  const handleInstallClick = () => {
    // eslint-disable-next-line no-undef
    window.open(
      'https://workspace.google.com/marketplace/app/syncsignature/651899331443',
      '_blank',
    );
  };

  return (
    <>
    <GoogleConnectDialog
        ref={dialogRef} // Pass ref to the dialog
      />
      <div className="bg-primary-foreground w-full h-[calc(100vh_-_71px)] overflow-auto">
      <div className="flex justify-between px-[24px] py-[18px]">
        <h1 className="m-0">Integrations</h1>
      </div>
      <div className="px-[24px]">
        <div className="flex flex-col items-center justify-center bg-primary-0">
          <div className="bg-white rounded-lg p-10 shadow-md text-center bg-white-0">
            <div>
              <GoogleWorkspaceIcon className="w-1/2" />
            </div>
            <p className="text-gray-600 mb-8 text-[15px] text-wrap max-w-[600px]">
              Centralize and Automate Signature Management across your company
              using Google Workspace. Simply install our Marketplace app and
              connect it here.
            </p>
            <Button
              className="mr-2"
              onClick={
                isGoogleWorkspaceConnected
                  ? handleDisconnectClick
                  : handleConnectClick
              }
              disabled={ removingToken}
            >
              {removingToken
                ? 'Loading...'
                : isGoogleWorkspaceConnected
                  ? 'Disconnect'
                  : 'Connect'}
            </Button>
            <Button onClick={handleInstallClick}>Install</Button>
          </div>
        </div>
      </div>
    </div>
    </>

  );
};

export default Integrations;
