import React, { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { EllipsisHorizontalIcon } from 'assets/svg';

const GroupCards = ({ name, actions = {}, data }) => {
  const {
    handleEdit = () => {},
    handleDelete = () => {},
    handleCardClick = () => {},
  } = actions;

  const items = [
    {
      onClick: () => {
        handleEdit(data);
      },
      label: <span>Edit</span>,
      key: '0',
      hide: false,
    },

    {
      onClick: (e) => {
        handleDelete(data);
      },
      label: <span>Delete</span>,
      key: '3',
      // hide: count === 1,
    },
  ];

  return (
    <div onClick={() => handleCardClick(data)}>
      <div
        className={`relative flex justify-start
    max-w-[200px] min-w-[200px] h-[130px] rounded-[8px] overflow-hidden shadow-lg cursor-pointer m-2
    bg-white border-[1px] border-solid border-secondary-400 hover:border-solid hover:border-[1px]  hover:border-primary hover:shadow-signature`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="absolute bottom-0 w-full box-border flex justify-between items-center px-3 py-1 bg-primary-foreground"
        >
          <div>{name}</div>
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <EllipsisHorizontalIcon />
              </DropdownMenuTrigger>
              <DropdownMenuContent className=" bg-white-0 border-solid border-1 border-gray-400 ">
                {items.map(({ label, key, onClick, hide = false }) => {
                  if (hide) {
                    return;
                  }
                  return (
                    <DropdownMenuItem
                      key={key}
                      value={key}
                      onClick={onClick}
                      className="w-[auto]"
                    >
                      {label}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupCards;
