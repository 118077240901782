/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleSeventeen({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const allUrlsAreNull =
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const ctadivider =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl') &&
    !getFieldData(fields, 'footer');
  return (
    <table
      // width={tableWidth}
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tbody>
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
                marginBottom: '8px',
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      cellPadding="0px"
                      cellSpacing="0"
                      border="0"
                      ref={mainContentRef}
                    >
                      <tbody>
                        {getFieldData(fields, 'headshotUrl') && (
                          <tr align="left">
                            <HeadshotImage
                              data={getFieldData(fields, 'headshotUrl')}
                              design={design}
                              align="left"
                              style={{
                                paddingBottom: '8px',
                              }}
                            />
                          </tr>
                        )}
                        <tr>
                          <td
                            style={{
                              paddingRight: allUrlsAreNull ? '0px' : '14px',
                            }}
                          >
                            <table cellPadding="0" cellSpacing="0">
                              <tbody>
                                {getFieldData(fields, 'name') && (
                                  <tr>
                                    {Name({
                                      signature,
                                      data: getFieldData(fields, 'name'),
                                      style: {
                                        fontFamily: 'inherit',
                                        textTransform: 'uppercase',
                                        whiteSpace: 'nowrap', // Prevent line breaks
                                      },
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'position') && (
                                  <tr>
                                    {Position({
                                      signature,
                                      data: getFieldData(fields, 'position'),
                                      style: {
                                        fontFamily: 'inherit',
                                        whiteSpace: 'nowrap', // Prevent line breaks
                                      },
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'department') && (
                                  <tr>
                                    {Department({
                                      signature,
                                      data: getFieldData(fields, 'department'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingLeft: '0px',
                                      },
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'companyName') && (
                                  <tr>
                                    {CompanyName({
                                      signature,
                                      data: getFieldData(fields, 'companyName'),
                                      style: {
                                        fontFamily: 'inherit',
                                        fontStyle: 'italic',
                                      },
                                    })}
                                  </tr>
                                )}

                                {!socialFields?.every(
                                  (obj) => obj?.value === '',
                                ) && (
                                  <tr>
                                    {SocialLinks({
                                      signature,
                                      showAwsIcons,
                                      data: socialFields,
                                      design,
                                      style: {
                                        paddingTop: '8px',
                                      },
                                    })}
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </td>
                          {!allUrlsAreNull && (
                            <td
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 0,
                                lineHeight: '100%',
                                borderLeft: `1px solid ${design?.primaryBrandColor}`,
                                paddingLeft: '12px',
                              }}
                            >
                              <table
                                cellPadding="0px"
                                cellSpacing="0"
                                border="0"
                                style={{
                                  borderCollapse: 'collapse',
                                }}
                              >
                                <tbody>
                                  {getFieldData(fields, 'phone') && (
                                    <tr>
                                      {Phone({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(fields, 'phone'),
                                        style: {
                                          paddingRight: '8px',
                                        },
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'email') && (
                                    <tr>
                                      {Email({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(fields, 'email'),
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'website') && (
                                    <tr>
                                      {Website({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(fields, 'website'),
                                        style: {},
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'companyAddress') && (
                                    <tr>
                                      {CompanyAddress({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: getFieldData(
                                          fields,
                                          'companyAddress',
                                        ),
                                        style: { paddingBottom: '2px' },
                                      })}
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {getFieldData(fields, 'companyLogo') && (
                  <tr>
                    {CompanyLogo({
                      data: getFieldData(fields, 'companyLogo'),
                      design,
                      style: {
                        paddingTop: '10px',
                      },
                    })}
                  </tr>
                )}

                {!ctadivider && (
                  <>
                    <tr>
                      <Divider
                        signature={signature}
                        style={{ marginTop: 8, borderBottom: 'none' }}
                      />
                    </tr>
                    <tr>
                      <table ref={extraContentRef}>
                        <tbody>
                          <tr>
                            {getFieldData(fields, 'ctaUrl') &&
                              getFieldData(fields, 'ctaTitle') &&
                              CallToAction({
                                signature,
                                showAwsIcons,
                                design,
                                data: {
                                  ctaUrl: getFieldData(fields, 'ctaUrl'),
                                  ctaTitle: getFieldData(fields, 'ctaTitle'),
                                },
                                style: {
                                  paddingBottom: '8px',
                                },
                                tdProps: { align: 'center' },
                              })}
                          </tr>
                          <tr>
                            {getFieldData(fields, 'ctaBannerImg') &&
                              getFieldData(fields, 'ctaBannerUrl') &&
                              CallToActionBanner({
                                data: {
                                  ctaBannerUrl: getFieldData(
                                    fields,
                                    'ctaBannerUrl',
                                  ),
                                  ctaBannerImg: getFieldData(
                                    fields,
                                    'ctaBannerImg',
                                  ),
                                },
                                design,
                                style: { textAlign: 'left' },
                              })}
                          </tr>
                        </tbody>
                      </table>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <table width={tableWidth}>
                          <tr>
                            {getFieldData(fields, 'footer') &&
                              Footer({
                                data: getFieldData(fields, 'footer'),
                                design,
                              })}
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <Branding hideBranding={hideBranding} />
        </tr>
      </tbody>
    </table>
  );
}

export default TemplateStyleSeventeen;
