/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Button } from 'components/ui/button';
import { useSendInviteTeammate } from 'hooks/teammate';
import { useGroupId, useWorkspaceId } from 'hooks/workspace';
import {
  ArrowUpDown,
  Search,
  PlusIcon,
  Pencil,
  Trash,
  Loader2,
} from 'lucide-react';
import { Input } from 'components/ui/input';
import Table from 'components/Table';
import { useRouter } from 'hooks/router';
import DataTableRowActions from 'modules/Teammates/components/DataTableRowActions';
import { toast } from 'hooks/use-toast';
import ActionDialog from 'modules/Teammates/components/ActionDialog';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import { useAppContext } from 'AppContext';
import useComponentToHtml from 'helpers/useComponentToHtml';
import { Checkbox } from 'components/ui/checkbox';
import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import {
  useGetValidAccessToken,
  useRemoveEmailSignatures,
  useSetEmailSignatures,
} from 'hooks/google-marketplace';
import { get } from 'lodash';
import { useSendSignature } from 'hooks/signature';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip';
import { delay } from 'modules/AiHeadshot/components/HeadshotGenerator/HeadshotGenerator';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES } from 'common/constants';
import AddExistingTeammateDialog from './components/AddExistingTeammateDialog';

const TeammateList = ({ workspaceTemplate, group }) => {
  const {
    state: { roles, groups, workspaceTeammates, isGoogleWorkspaceConnected },
  } = useWorkspaceContext();
  const {
    state: { currentUser },
  } = useAppContext();
  const { navigate } = useRouter();
  const [searchQuery, setSearchQuery] = useState('');
  const tableRef = useRef(null);
  const { workspaceId } = useWorkspaceId();
  const { groupId } = useGroupId();
  const [sendSignature] = useSendSignature();
  const addExistingTeammateDialogRef = useRef();
  const actionDialogRef = useRef();
  const [sendInviteTeammate] = useSendInviteTeammate();
  const [selectedTeammates, setSelectedTeammates] = useState([]);
  const { convertToHtml } = useComponentToHtml();

  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();

  const [getValidAccessToken] = useGetValidAccessToken();

  const [setEmailSignatures] = useSetEmailSignatures();
  const [removeEmailSignatures] = useRemoveEmailSignatures();
  const { isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();
  const fetchWorkspaceTeammates = useCallback(async () => {
    await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
  }, [workspaceId, getWorkspaceTeammateByWorkspaceId]);

  const [loading, setLoading] = useState(false);
  const [sendSignatureLoading, setSendSignatureLoading] = useState(false);
  const [sendInviteLoading, setSendInviteLoading] = useState(false);
  const [installSignatureLoading, setInstallSignatureLoading] = useState(false);
  const [removeSignatureLoading, setRemoveSignatureLoading] = useState(false);

  const hideBranding =
    isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
    role === ROLES.TEAMMATE ||
    role === ROLES.MANAGER;

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspaceTeammates();
    }
  }, [workspaceId, fetchWorkspaceTeammates]);

  const getTeammateRole = useCallback(
    (roleId) => {
      if (workspaceTeammates.length || roles.length) {
        const role = roles?.find((r) => Number(r.id) === roleId);
        return role?.name || '';
      }
      return '';
    },
    [workspaceTeammates, roles],
  );

  const getTeammateGroup = useCallback(
    (groupId) => {
      const group = groups.find((group) => group.id === groupId);
      return group || null;
    },
    [groups],
  );

  const getTeammateRoleFull = useCallback(
    (roleId) => {
      if (workspaceTeammates.length || roles.length) {
        const role = roles?.find((r) => Number(r.id) === roleId);
        return role || null;
      }
      return null;
    },
    [workspaceTeammates, roles],
  );

  const filteredTeammates = useMemo(
    () =>
      workspaceTeammates
        ?.filter(
          (teammate) =>
            teammate?.workspaceTeammateDetails?.name
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase()) ||
            teammate?.workspaceTeammateDetails?.email
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase()),
        )
        ?.filter((wt) => wt.groupId === groupId) ?? [],
    [workspaceTeammates, searchQuery, groupId],
  );

  const handleRemoveEmailSignatures = async () => {
    setRemoveSignatureLoading(true); // Start loading

    const teammatesToProcess =
      selectedTeammates.length > 0
        ? filteredTeammates.filter((teammate) =>
            selectedTeammates.includes(teammate.id),
          )
        : filteredTeammates;

    if (!teammatesToProcess || teammatesToProcess.length === 0) {
      toast({
        closeicn: 'destructive',
        description: 'No teammates to remove signatures from',
      });
      setRemoveSignatureLoading(false); // Stop loading if no teammates found
      return;
    }

    const userSignatures = teammatesToProcess.map((teammate) => ({
      userEmail: teammate.workspaceTeammateDetails?.email,
      signatureHtml: '',
      signatureId: teammate.signature?.id,
    }));

    try {
      const res = await removeEmailSignatures({
        variables: {
          where: {
            userSignatures,
          },
        },
      });

      if (!res) {
        throw new Error('Failed to remove signature');
      }

      toast({
        closeicn: 'success',
        description: 'Signatures removed successfully!',
      });
    } catch (error) {
      toast({
        closeicn: 'destructive',
        description: error.message || 'Failed to remove signature',
      });
    } finally {
      setRemoveSignatureLoading(false); // Stop loading after function completes
    }
  };

  const handleInstallSignature = async () => {
    setInstallSignatureLoading(true); // Set loading to true when function starts

    const teammatesToProcess =
      selectedTeammates.length > 0
        ? filteredTeammates.filter((teammate) =>
            selectedTeammates.includes(teammate.id),
          )
        : filteredTeammates;

    if (!teammatesToProcess || teammatesToProcess.length === 0) {
      toast({
        closeicn: 'destructive',
        description: 'No teammates to install',
      });
      setInstallSignatureLoading(false); // Stop loading if no teammates found
      return;
    }

    const userSignatures = [];
    for (const teammate of teammatesToProcess) {
      const { signature } = teammate;
      if (!signature || !signature.template?.key) {
        // eslint-disable-next-line no-continue
        continue; // Skip this teammate if no valid signature is found
      }
      // Dynamic template rendering based on the signature template key
      const SignatureComponent = dynamicTemplate({
        templateKey: signature.template.key,
      });

      // Convert the component to HTML
      const html = convertToHtml(SignatureComponent, {
        signature,
        showAwsIcons: true,
        hideBranding: { hideBranding },
      });
      const email = teammate.workspaceTeammateDetails?.email;
      if (email) {
        const userSignature = {
          userEmail: email,
          signatureHtml: html,
          signatureId: signature?.id,
        };
        userSignatures.push(userSignature);
      }
    }

    try {
      const res = await setEmailSignatures({
        variables: {
          where: {
            userSignatures,
          },
        },
      });

      if (!res) {
        throw new Error('Failed to deploy signature');
      }

      toast({
        closeicn: 'success',
        description: 'Signatures installed successfully!',
      });
    } catch (error) {
      toast({
        closeicn: 'destructive',
        description: error.message || 'Failed to deploy signature',
      });
    } finally {
      setInstallSignatureLoading(false); // Always stop loading after the function completes
    }
  };

  const sendInvitesToTeammates = async () => {
    setSendInviteLoading(true); // Set loading to true when function is triggered
    await delay(1000);
    const selectedTeammateObjects = filteredTeammates.filter((teammate) =>
      selectedTeammates.includes(teammate.id),
    );

    const teammatesToInvite =
      selectedTeammates.length > 0
        ? selectedTeammateObjects
        : filteredTeammates;

    if (!teammatesToInvite || teammatesToInvite.length === 0) {
      toast({
        closeicn: 'destructive',
        description: 'No teammates to invite',
      });
      setSendInviteLoading(false); // Stop loading if no teammates to process
      return;
    }

    const emails = teammatesToInvite
      .filter((teammate) => teammate.workspaceTeammateDetails?.email)
      .map((teammate) => teammate.workspaceTeammateDetails.email);

    if (emails.length === 0) {
      toast({
        closeicn: 'destructive',
        description: 'No valid emails found for sending invites',
      });
      setSendInviteLoading(false); // Stop loading if no valid emails
      return;
    }

    try {
      await sendInviteTeammate({
        variables: {
          data: {
            emails,
            workspaceId,
          },
        },
      });
      toast({
        closeicn: 'success',
        description: 'Invites sent successfully',
      });
    } catch (error) {
      toast({
        closeicn: 'destructive',
        description: 'Failed to send invites',
      });
    } finally {
      setSendInviteLoading(false); // Always stop loading after the function completes
    }
  };

  const sendSignaturesToTeammates = async () => {
    await delay(3000);
    const selectedTeammateObjects = filteredTeammates.filter((teammate) =>
      selectedTeammates.includes(teammate.id),
    );

    const teammatesToProcess =
      selectedTeammates.length > 0
        ? selectedTeammateObjects
        : filteredTeammates;

    if (!teammatesToProcess || teammatesToProcess.length === 0) {
      toast({
        closeicn: 'destructive',
        description: 'No teammates to send signatures to',
      });
      setSendSignatureLoading(false); // Stop loading if no teammates to process
      return;
    }

    // Loop through each teammate and their signature
    for (const teammate of teammatesToProcess) {
      const { signature } = teammate;

      // Ensure that the signature and its template key are valid
      if (!signature || !signature.template?.key) {
        // eslint-disable-next-line no-continue
        continue; // Skip this teammate if no valid signature is found
      }

      // Dynamic template rendering based on the signature template key
      const SignatureComponent = dynamicTemplate({
        templateKey: signature.template.key,
      });

      // Convert the component to HTML
      const html = convertToHtml(SignatureComponent, {
        signature,
        showAwsIcons: true,
        hideBranding: { hideBranding },
      });

      try {
        // Send the signature HTML to the teammate's email
        const email = teammate.workspaceTeammateDetails?.email;
        if (email) {
          await sendSignature({
            variables: {
              data: {
                email,
                html,
              },
            },
          });
        }
      } catch (error) {
        toast({
          closeicn: 'destructive',
          description: `Failed to send signature to ${teammate.workspaceTeammateDetails?.email}`,
        });
      }
    }

    toast({
      closeicn: 'success',
      description: 'Signatures sent successfully!',
    });
    setSendSignatureLoading(false); // Always stop loading after the function completes
  };

  const handleCheckboxChange = (id) => {
    setSelectedTeammates((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((teammateId) => teammateId !== id);
      }
      return [...prevSelected, id];
    });
  };

  // const isAllSelected =
  //   workspaceTeammates.length > 0 &&
  //   workspaceTeammates.every((teammate) =>
  //     selectedTeammates.includes(teammate.id),
  //   );

  const toggleSelectAll = (value) => {
    if (!value) {
      setSelectedTeammates([]);
    } else {
      setSelectedTeammates(workspaceTeammates.map((teammate) => teammate.id));
    }
  };
  // Memoize columns to avoid recreating the array on each render
  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && 'indeterminate')
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value);
              // eslint-disable-next-line no-console
              console.log(value);
              toggleSelectAll(value);
            }}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
              handleCheckboxChange(row.original.id);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: 'workspaceTeammateDetails.name', // Update this to match the correct nested key if needed
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer font-bold"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        ),
        cell: ({ row }) => {
          const getInitials = (name) => {
            const parts = name?.split(' ');
            return parts?.map((part) => part[0])?.join('');
          };

          const name = row.original.workspaceTeammateDetails?.name; // Accessing nested key directly
          const initials = getInitials(name);

          return (
            <div className="flex items-center">
              <div className="ml-3">
                <div className="font-medium">{name}</div>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'workspaceTeammateDetails.email', // Directly access nested email
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer font-bold"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Email
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        ),
        cell: ({ row }) => (
          <div className="lowercase">
            {row.original.workspaceTeammateDetails?.email}
          </div> // Accessing nested key directly
        ),
      },
      {
        accessorKey: 'group',
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer font-bold"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Group
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        ),
        cell: ({ row }) => (
          <div className="capitalize">
            {getTeammateGroup(row?.original?.groupId)?.name ??
              'No group assigned'}
          </div>
        ),
      },
      {
        accessorKey: 'role',
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer font-bold"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Role
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center gap-2">
            <div className="lowercase">
              {getTeammateRole(row?.original?.roleId)}
            </div>
            {getTeammateRole(row?.original?.roleId) !== ROLES.OWNER ? (
              <Button
                size="sm"
                className="group flex items-center px-2 py-0 bg-gray-100 text-gray-600 rounded-md h-6"
                onClick={() => {
                  actionDialogRef.current.onTypeChange('update-role');
                  const roleId = getTeammateRoleFull(row?.original?.roleId)?.id;
                  actionDialogRef.current.onDataChnage({
                    ...row.original,
                    roleId,
                  });
                  actionDialogRef?.current?.open();
                }}
              >
                <Pencil className="h-3 w-3 text-primary group-hover:text-[white]" />
              </Button>
            ) : (
              <div />
            )}
          </div>
        ),
      },
      {
        id: 'installation',
        accessorKey: 'signature.installation',
        header: ({ column }) => (
          <div
            className="flex items-center cursor-pointer font-bold"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            installation
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        ),
        cell: ({ row }) => {
          const installationMap = {
            INSTALLED: 'installed',
            PENDING: 'pending',
            NOT_INSTALLED: 'not installed',
          };
          const installation =
            installationMap[row.getValue('installation')] ?? 'not installed';
          return <div className="capitalize">{installation}</div>;
        },
      },
      {
        id: 'actions',
        cell: ({ row: r }) => (
          <div className="flex justify-end items-center gap-1">
            <div
              onClick={() => {
                navigate(
                  `/app/workspace/${workspaceId}/teammates/${r.original.id}`,
                );
              }}
            >
              <Button
                size="sm"
                className="group flex items-center px-2 py-0 bg-gray-100 text-gray-600 rounded-md h-6"
              >
                <Pencil className="h-3 w-3 text-primary group-hover:text-[white]" />
              </Button>
            </div>
            <div>
              <DataTableRowActions
                onRowClick={async ({ type }) => {
                  const groupId = getTeammateGroup(r?.original?.groupId)?.id;
                  if (type === 'send-invite') {
                    if (!groupId) {
                      toast({
                        closeicn: 'destructive',
                        description: 'Please assign a group',
                      });
                      return;
                    }
                    await sendInviteTeammate({
                      variables: {
                        data: {
                          emails: [
                            r?.original?.workspaceTeammateDetails?.email,
                          ],
                          workspaceId,
                        },
                      },
                    });
                    return;
                  }
                  if (type === 'send-signature') {
                    if (!groupId) {
                      toast({
                        closeicn: 'destructive',
                        description: 'Please assign a group',
                      });
                      return;
                    }
                  }
                  actionDialogRef.current.onTypeChange(type);
                  actionDialogRef.current.onDataChnage(r.original);
                  actionDialogRef?.current?.open();
                }}
              />
            </div>
          </div>
        ),
      },
    ],
    [
      workspaceTeammates,
      selectedTeammates,
      roles,
      groups,
      actionDialogRef,
      workspaceId,
      sendInviteTeammate,
    ],
  );

  const handleAddTeammatesButton = async () => {
    addExistingTeammateDialogRef.current.open();
  };

  const customHeader = (
    <div className="flex items-center justify-between py-0 mt-4 gap-4">
      <div className="relative">
        <Input
          onChange={(event) => {
            const { value } = event.target;
            setSearchQuery(value);
          }}
          className="bg-white-0 rounded transition duration-300 focus-within hover:border-1  hover:border-solid h-[30px] hover:border-primary hover:bg-primary-foreground  hover:shadow-custom py-1 pl-8 w-96"
          placeholder="Search teammates"
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
          <Search className="text-gray-400 h-4 w-4" />
        </div>
      </div>
      <div className="">
        {selectedTeammates.length > 0 ? (
          <>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className="h-[40px] mr-2 text-[16px]"
                    onClick={handleInstallSignature}
                    disabled={
                      !workspaceTemplate ||
                      !isGoogleWorkspaceConnected ||
                      installSignatureLoading
                    }
                  >
                    <PlusIcon className="h-3 w-3 mr-1" />
                    Install Signature
                    {installSignatureLoading && (
                      <Loader2 className="animate-spin ml-2 h-5 w-5" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="top" align="center">
                  <p>Connect to integration to deploy user</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {/* <Button
              className="h-[40px] mr-2 text-[16px]"
              onClick={handleRemoveEmailSignatures}
              disabled={
                !workspaceTemplate ||
                !isGoogleWorkspaceConnected ||
                removeSignatureLoading
              } // Make sure this is disabled when loading
            >
              <Trash className="h-3 w-3 mr-1" />
              Uninstall Signature
              {removeSignatureLoading && (
                <Loader2 className="animate-spin ml-2 h-5 w-5" />
              )}
            </Button> */}
            <Button
              className="h-[40px] mr-2 text-[16px]"
              onClick={() => {
                sendSignaturesToTeammates();
              }}
              disabled={!workspaceTemplate || sendSignatureLoading}
            >
              <PlusIcon className="h-3 w-3 mr-1" />
              Send Signature{' '}
              {sendSignatureLoading && (
                <Loader2 className="animate-spin ml-2 h-5 w-5 " />
              )}
            </Button>
            <Button
              className="h-[40px] mr-2 text-[16px]"
              onClick={sendInvitesToTeammates}
              disabled={!workspaceTemplate || sendInviteLoading}
            >
              <PlusIcon className="h-3 w-3 mr-1" />
              Send Invite
              {sendInviteLoading && (
                <Loader2 className="animate-spin ml-2 h-5 w-5" />
              )}
            </Button>
          </>
        ) : (
          <Button
            className="h-[40px] text-[16px]"
            onClick={handleAddTeammatesButton}
            disabled={!workspaceTemplate}
          >
            <PlusIcon className="h-3 w-3  mr-1" />
            Add Teammate
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <ActionDialog ref={actionDialogRef} />
      <AddExistingTeammateDialog
        ref={addExistingTeammateDialogRef}
        group={group}
        workspaceTeammates={workspaceTeammates}
      />
      <div className="bg-primary-foreground m-4 overflow-auto ">
        <div className="px-[24px] ">
          <Table
            ref={tableRef}
            data={filteredTeammates}
            columns={columns}
            customHeader={customHeader}
            loading={loading}
            tableClassName="max-h-[calc(100vh_-_230px)] overflow-auto"
          />
        </div>
      </div>
    </>
  );
};

export default TeammateList;
