/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleSixteen({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  return (
    <table
      // width={tableWidth}
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tbody>
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
                marginBottom: '8px',
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      cellPadding="0px"
                      cellSpacing="0"
                      border="0"
                      ref={mainContentRef}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {(getFieldData(fields, 'headshotUrl') ||
                              getFieldData(fields, 'companyLogo')) && (
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                  paddingRight: '14px',
                                }}
                              >
                                <tbody>
                                  <tr align="center">
                                    {getFieldData(fields, 'headshotUrl') &&
                                      HeadshotImage({
                                        data: getFieldData(
                                          fields,
                                          'headshotUrl',
                                        ),
                                        design,
                                        style: {
                                          paddingBottom: '14px',
                                          paddingTop: '9px',
                                        },
                                      })}
                                  </tr>
                                  {getFieldData(fields, 'companyLogo') && (
                                    <tr align="center">
                                      {CompanyLogo({
                                        data: getFieldData(
                                          fields,
                                          'companyLogo',
                                        ),
                                        design,
                                      })}
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            )}
                          </td>
                          <td>
                            <table
                              cellPadding="0px"
                              cellSpacing="0"
                              border="0"
                              style={{
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      border="0"
                                      style={{
                                        paddingBottom: '8px',
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          {getFieldData(fields, 'name') &&
                                            Name({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'name',
                                              ),
                                              style: {
                                                fontWeight: 700,
                                                fontFamily: 'inherit',
                                                textTransform: 'uppercase',
                                              },
                                            })}
                                        </tr>
                                        <tr>
                                          <td>
                                            <table
                                              cellPadding="0"
                                              cellSpacing="0"
                                              border="0"
                                              style={{
                                                borderCollapse:
                                                  'collapse !important',
                                                fontSize: 'inherit',
                                              }}
                                            >
                                              <tbody>
                                                <tr valign="center">
                                                  {getFieldData(
                                                    fields,
                                                    'position',
                                                  ) &&
                                                    Position({
                                                      signature,
                                                      data: getFieldData(
                                                        fields,
                                                        'position',
                                                      ),
                                                      style: {
                                                        fontFamily: 'inherit',
                                                        fontWeight: '700',
                                                      },
                                                    })}

                                                  <td>
                                                    {getFieldData(
                                                      fields,
                                                      'position',
                                                    ) &&
                                                      getFieldData(
                                                        fields,
                                                        'companyName',
                                                      ) && (
                                                        <span
                                                          style={{
                                                            padding: '0 8px',
                                                            color: 'black',
                                                          }}
                                                        >
                                                          {' '}
                                                          |{' '}
                                                        </span>
                                                      )}
                                                  </td>

                                                  {getFieldData(
                                                    fields,
                                                    'companyName',
                                                  ) &&
                                                    CompanyName({
                                                      signature,
                                                      data: getFieldData(
                                                        fields,
                                                        'companyName',
                                                      ),
                                                      style: {
                                                        fontFamily: 'inherit',
                                                        fontWeight: '700',
                                                      },
                                                    })}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>

                                {getFieldData(fields, 'department') && (
                                  <tr style={{ textAlign: 'left' }}>
                                    <Divider
                                      signature={signature}
                                      style={{
                                        width: '50px',
                                        display: 'inline-block',
                                      }}
                                    />
                                  </tr>
                                )}
                                {getFieldData(fields, 'department') && (
                                  <tr>
                                    {Department({
                                      signature,
                                      data: getFieldData(fields, 'department'),
                                      style: {
                                        fontFamily: 'inherit',
                                        fontWeight: '700',
                                      },
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'phone') && (
                                  <tr>
                                    {Phone({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'phone'),
                                      style: {
                                        paddingRight: '8px',
                                      },
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'email') && (
                                  <tr>
                                    {Email({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'email'),
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'website') && (
                                  <tr>
                                    {Website({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'website'),
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'companyAddress') && (
                                  <tr>
                                    {CompanyAddress({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyAddress',
                                      ),
                                      style: { paddingBottom: '2px' },
                                    })}
                                  </tr>
                                )}
                                {!socialFields?.every(
                                  (obj) => obj?.value === '',
                                ) && (
                                  <tr>
                                    {SocialLinks({
                                      signature,
                                      showAwsIcons,
                                      data: socialFields,
                                      design,
                                      style: { paddingBottom: '8px' },
                                    })}
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table width={tableWidth} ref={extraContentRef}>
                      <tr>
                        {getFieldData(fields, 'ctaUrl') &&
                          getFieldData(fields, 'ctaTitle') &&
                          CallToAction({
                            signature,
                            showAwsIcons,
                            design,
                            data: {
                              ctaUrl: getFieldData(fields, 'ctaUrl'),
                              ctaTitle: getFieldData(fields, 'ctaTitle'),
                            },
                            style: {
                              paddingBottom: '8px',
                            },
                          })}
                      </tr>
                      <tr>
                        {getFieldData(fields, 'ctaBannerImg') &&
                          getFieldData(fields, 'ctaBannerUrl') &&
                          CallToActionBanner({
                            data: {
                              ctaBannerUrl: getFieldData(
                                fields,
                                'ctaBannerUrl',
                              ),
                              ctaBannerImg: getFieldData(
                                fields,
                                'ctaBannerImg',
                              ),
                            },
                            design,
                            style: { textAlign: 'left', paddingBottom: '6px' },
                          })}
                      </tr>
                      <tr>
                        {getFieldData(fields, 'footer') &&
                          Footer({
                            data: getFieldData(fields, 'footer'),
                            design,
                          })}
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <Branding hideBranding={hideBranding} />
        </tr>
      </tbody>
    </table>
  );
}

export default TemplateStyleSixteen;
