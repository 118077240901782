import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { get } from 'lodash';
import { getFieldData, getLiveUrl } from 'common/utils';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import { useTableWidth } from '../../../hooks/table';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleEleven({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef } = useTableWidth(downloadUrl);
  const { design, fields } = signature || {};
  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    socialFields?.every((obj) => obj?.value === '');

  const allUrlsAreNull =
    !getFieldData(fields, 'companyLogo') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  return (
    <table
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tr>
        <td aria-label="main-content">
          <table
            cellPadding="0"
            cellSpacing="0"
            border="0"
            style={{
              borderCollapse: 'collapse',
              fontSize: 'inherit',
              marginBottom: '8px',
            }}
          >
            <tr>
              <td>
                <table
                  cellPadding="0px"
                  cellSpacing="0"
                  border="0"
                  ref={mainContentRef}
                >
                  <tr>
                    {!section1 && (
                      <td style={{ paddingRight: '14px' }}>
                        <table>
                          {getFieldData(fields, 'headshotUrl') && (
                            <tr align="center">
                              {HeadshotImage({
                                data: getFieldData(fields, 'headshotUrl'),
                                design,
                                style: {},
                              })}
                            </tr>
                          )}

                          {!!socialFields?.length && (
                            <tr align="center">
                              {SocialLinks({
                                signature,
                                showAwsIcons,
                                data: socialFields,
                                design,
                                style: {
                                  paddingTop: '8px',
                                },
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    )}

                    <td>
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        border="0"
                        style={{
                          borderCollapse: 'collapse',
                        }}
                      >
                        {getFieldData(fields, 'name') && (
                          <tr>
                            {Name({
                              signature,
                              data: getFieldData(fields, 'name'),
                              style: {
                                fontWeight: 700,
                                color: design?.primaryBrandColor,
                                fontFamily: 'inherit',
                              },
                            })}
                          </tr>
                        )}

                        {(getFieldData(fields, 'department') ||
                          getFieldData(fields, 'companyName') ||
                          getFieldData(fields, 'position')) && (
                          <tr>
                            <td
                              style={{
                                paddingBottom: '8px',
                                borderBottom: `1px solid ${design?.primaryBrandColor}`,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                style={{
                                  borderCollapse: 'collapse !important',
                                  fontSize: 'inherit',
                                }}
                              >
                                <tr>
                                  {getFieldData(fields, 'department') &&
                                    Department({
                                      signature,
                                      data: getFieldData(fields, 'department'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingRight: '8px',
                                      },
                                    })}

                                  {getFieldData(fields, 'companyName') &&
                                    CompanyName({
                                      signature,
                                      data: getFieldData(fields, 'companyName'),
                                      style: {
                                        fontFamily: 'inherit',
                                      },
                                    })}

                                  {getFieldData(fields, 'position') &&
                                    Position({
                                      signature,
                                      data: getFieldData(fields, 'position'),
                                      style: {
                                        fontFamily: 'inherit',
                                      },
                                    })}
                                </tr>
                              </table>
                            </td>
                          </tr>
                        )}

                        {(getFieldData(fields, 'phone') ||
                          getFieldData(fields, 'email')) && (
                          <tr>
                            <td style={{ paddingTop: '8px' }}>
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                style={{
                                  borderCollapse: 'collapse',
                                }}
                              >
                                <tr>
                                  {getFieldData(fields, 'phone') &&
                                    Phone({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'phone'),
                                      style: {
                                        paddingRight: '8px',
                                        size: '20',
                                      },
                                    })}
                                  {getFieldData(fields, 'email') &&
                                    Email({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'email'),
                                      style: {
                                        size: '20',
                                        color: '#000',
                                      },
                                    })}
                                </tr>
                              </table>
                            </td>
                          </tr>
                        )}

                        {getFieldData(fields, 'website') && (
                          <tr>
                            {Website({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'website'),
                              style: {
                                size: '20',
                                color: '#000',
                              },
                            })}
                          </tr>
                        )}

                        {getFieldData(fields, 'companyAddress') && (
                          <tr>
                            {CompanyAddress({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'companyAddress'),
                              style: {
                                paddingBottom: '2px',
                                size: '20',
                              },
                            })}
                          </tr>
                        )}

                        {getFieldData(fields, 'companyLogo') && (
                          <tr style={{ textAlign: 'left' }}>
                            {CompanyLogo({
                              data: getFieldData(fields, 'companyLogo'),
                              design,
                              style: { textAlign: 'center' },
                            })}
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr style={{ marginTop: '0' }}>
              {' '}
              <td>
                <table width={tableWidth}>
                  {getFieldData(fields, 'ctaUrl') &&
                    getFieldData(fields, 'ctaTitle') && (
                      <tr>
                        {CallToAction({
                          signature,
                          showAwsIcons,
                          design,
                          data: {
                            ctaUrl: getFieldData(fields, 'ctaUrl'),
                            ctaTitle: getFieldData(fields, 'ctaTitle'),
                          },
                          style: {},
                        })}
                      </tr>
                    )}

                  {getFieldData(fields, 'ctaBannerImg') &&
                    getFieldData(fields, 'ctaBannerUrl') && (
                      <tr>
                        {CallToActionBanner({
                          data: {
                            ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                            ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                          },
                          design,
                          style: { textAlign: 'left', paddingTop: '8px' },
                        })}
                      </tr>
                    )}

                  {getFieldData(fields, 'footer') && (
                    <tr>
                      {Footer({
                        data: getFieldData(fields, 'footer'),
                        design,
                      })}
                    </tr>
                  )}
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <Branding hideBranding={hideBranding} />
      </tr>
    </table>
  );
}

export default TemplateStyleEleven;
