/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleTwentyThree({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );
  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'name') &&
    socialFields?.every((obj) => obj?.value === '');

  return (
    <table
      // width={tableWidth}
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tbody>
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
                marginBottom: '8px',
              }}
            >
              <tbody>
                <tr marginBottom="8px">
                  <td>
                    <table
                      cellPadding="0px"
                      cellSpacing="0"
                      border="0"
                      ref={mainContentRef}
                    >
                      <tbody>
                        <tr>
                          {!section1 && (
                            <td
                              style={{
                                paddingRight: '14px',
                                borderRight: `1px solid ${design?.primaryBrandColor}`,
                                lineHeight: '100%',
                              }}
                            >
                              <table cellPadding="0" cellSpacing="0">
                                <tbody>
                                  {getFieldData(fields, 'headshotUrl') && (
                                    <tr align="center">
                                      {HeadshotImage({
                                        data: getFieldData(
                                          fields,
                                          'headshotUrl',
                                        ),
                                        design,
                                        style: {},
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'name') && (
                                    <tr>
                                      {Name({
                                        signature,
                                        data: getFieldData(fields, 'name'),
                                        style: {
                                          fontWeight: 700,
                                          fontFamily: 'inherit',
                                          textTransform: 'uppercase',
                                          whiteSpace: 'nowrap',
                                          paddingTop: '9px',
                                        },
                                      })}
                                    </tr>
                                  )}

                                  <tr>
                                    <td align="center">
                                      <table cellPadding="0">
                                        {!socialFields?.every(
                                          (obj) => obj?.value === '',
                                        ) && (
                                          <tr>
                                            {SocialLinks({
                                              signature,
                                              showAwsIcons,
                                              data: socialFields,
                                              design,
                                              style: {
                                                paddingTop: '8px',
                                              },
                                            })}
                                          </tr>
                                        )}
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          )}

                          <td
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 0,
                              paddingLeft: '14px',
                            }}
                          >
                            <table
                              cellPadding="0px"
                              cellSpacing="0"
                              border="0"
                              style={{
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tbody>
                                {(getFieldData(fields, 'position') ||
                                  getFieldData(fields, 'companyName')) && (
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding="0px"
                                        cellSpacing="0"
                                        border="0"
                                        style={{
                                          borderCollapse: 'collapse !important',
                                          fontSize: 'inherit',
                                          paddingBottom: '4px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            {getFieldData(fields, 'position') &&
                                              Position({
                                                signature,
                                                data: getFieldData(
                                                  fields,
                                                  'position',
                                                ),
                                                style: {
                                                  fontFamily: 'inherit',
                                                  paddingRight: '10px',
                                                },
                                              })}

                                            {getFieldData(
                                              fields,
                                              'companyName',
                                            ) &&
                                              CompanyName({
                                                signature,
                                                data: getFieldData(
                                                  fields,
                                                  'companyName',
                                                ),
                                                style: {
                                                  fontFamily: 'inherit',
                                                },
                                              })}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                )}

                                {getFieldData(fields, 'department') && (
                                  <tr>
                                    {Department({
                                      signature,
                                      data: getFieldData(fields, 'department'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingRight: '8px',
                                      },
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'phone') && (
                                  <tr>
                                    {Phone({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'phone'),
                                      style: {},
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'email') && (
                                  <tr>
                                    {Email({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'email'),
                                      style: {},
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'website') && (
                                  <tr>
                                    {Website({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'website'),
                                      style: {},
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'companyAddress') && (
                                  <tr>
                                    {CompanyAddress({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyAddress',
                                      ),
                                      style: {},
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'companyLogo') && (
                                  <tr>
                                    {CompanyLogo({
                                      data: getFieldData(fields, 'companyLogo'),
                                      design,
                                      style: {
                                        textAlign: 'left',
                                        paddingTop: '4px',
                                      },
                                    })}
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table ref={extraContentRef}>
                      {getFieldData(fields, 'ctaUrl') &&
                        getFieldData(fields, 'ctaTitle') && (
                          <tr>
                            {CallToAction({
                              signature,
                              showAwsIcons,
                              design,
                              data: {
                                ctaUrl: getFieldData(fields, 'ctaUrl'),
                                ctaTitle: getFieldData(fields, 'ctaTitle'),
                              },
                              style: {},
                            })}
                          </tr>
                        )}

                      {getFieldData(fields, 'ctaBannerImg') &&
                        getFieldData(fields, 'ctaBannerUrl') && (
                          <tr>
                            {CallToActionBanner({
                              data: {
                                ctaBannerUrl: getFieldData(
                                  fields,
                                  'ctaBannerUrl',
                                ),
                                ctaBannerImg: getFieldData(
                                  fields,
                                  'ctaBannerImg',
                                ),
                              },
                              design,
                              style: { textAlign: 'left', paddingTop: '8px' },
                            })}
                          </tr>
                        )}
                    </table>
                  </td>
                </tr>
                {getFieldData(fields, 'footer') && (
                  <tr>
                    <td>
                      <table width={tableWidth}>
                        <tr>
                          {Footer({
                            data: getFieldData(fields, 'footer'),
                            design,
                          })}
                        </tr>
                      </table>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <Branding hideBranding={hideBranding} />
        </tr>
      </tbody>
    </table>
  );
}

export default TemplateStyleTwentyThree;
