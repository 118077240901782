/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useAppContext } from 'AppContext';
import { get } from 'lodash';
import Button from 'components/Button';
import { formatDateTime } from 'common/utils';
import { useCancelSubscriptionUrl, useSubscriptionDetails } from 'hooks/plans';
import {
  useTotalSignatureWorkspace,
  useFetchWorkspaces,
} from 'hooks/workspace';

function BillingPage() {
  const {
    state: { currentUser },
  } = useAppContext();

  const { getBillingDetails, fetchSubscriptionDetails } =
    useSubscriptionDetails();
  const signatureLimit = get(currentUser, 'signatureLimit', null);
  const [getCancelSubscriptionUrl, { loading, error }] =
    useCancelSubscriptionUrl();
  const billingDetails = getBillingDetails();
  const [fetchWorkspaces] = useFetchWorkspaces();
  const { totalSignatureUsed } = useTotalSignatureWorkspace();

  useEffect(() => {
    fetchSubscriptionDetails(currentUser?.id);
  }, [currentUser?.id]);

  useEffect(() => {
    // Fetch workspaces on component mount
    fetchWorkspaces();
  }, [fetchWorkspaces]);

  const handleCancelSubscription = async () => {
    if (currentUser?.id) {
      try {
        // Trigger the lazy query to get the cancel subscription URL
        const result = await getCancelSubscriptionUrl();

        // Extract the URL from the result data and redirect the user
        const cancelUrl = result?.data?.getCancelSubscriptionUrl?.url;

        if (cancelUrl) {
          window.open(cancelUrl, '_blank'); // Open the cancel URL in a new tab
        }
      } catch (err) {
        // console.error('Error fetching cancel subscription URL', err);
      }
    }
  };

  return (
    <div className="bg-primary-foreground h-[calc(100vh_-_71px)] flex justify-center">
      <div className="p-[32px] w-full max-w-sm">
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[40px] flex justify-center">
          <div className="mr-4">Billing</div>
        </div>
        <div className="flex justify-center">
          {currentUser?.activePlan?.name && (
            <div className="flex w-full justify-between items-center bg-white-0 shadow-lg p-6 rounded-md">
              <div className="flex flex-col items-center">
                <div className="text-h6 text-primary">Active Plan</div>
                <div className="text-regular-l">
                  {currentUser?.activePlan?.name}
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-h6 text-primary">Signature Limit</div>
                <div className="text-regular-l">{signatureLimit}</div>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-h6 text-primary">Signature Used</div>
                <div className="text-regular-l">{totalSignatureUsed}</div>
              </div>
            </div>
          )}
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-primary mb-4">
            Billing Details
          </h2>
          {billingDetails ? (
            <div className="bg-white-0 p-6 rounded-md shadow-md">
              <p className="text-primary">
                <strong>Billing Start Date:</strong>{' '}
                {formatDateTime(billingDetails.billingPeriod?.starts_at)}
              </p>
              {/* <p className="text-primary">
                <strong>Billing End Date:</strong>{' '}
                {formatDateTime(billingDetails.billingPeriod?.ends_at)}
              </p> */}
              <p className="text-primary">
                <strong>Next Billing Date:</strong>{' '}
                {formatDateTime(billingDetails.nextBilledAt)}
              </p>
            </div>
          ) : (
            <p className="text-primary">No billing details available.</p>
          )}
        </div>
        {/* Cancel Subscription Button */}
        {/* <div className="flex justify-center mt-8">
          {currentUser?.activePlan?.name && (
            <Button
              onClick={handleCancelSubscription}
              className="bg-red-600 text-white py-2 px-4 rounded-md"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Cancel Subscription'}
            </Button>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default BillingPage;
