/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleFourteen({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef } = useTableWidth(downloadUrl);
  const { design, fields } = signature || {};
  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle');

  return (
    <table
      // width={tableWidth}
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tbody>
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
                marginBottom: '8px',
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      cellPadding="0px"
                      cellSpacing="0"
                      border="0"
                      ref={mainContentRef}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {!section1 && (
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                  verticalAlign: 'center',
                                  paddingRight: '14px',
                                }}
                              >
                                <tbody>
                                  {getFieldData(fields, 'headshotUrl') && (
                                    <tr align="center">
                                      {HeadshotImage({
                                        data: getFieldData(
                                          fields,
                                          'headshotUrl',
                                        ),
                                        design,
                                      })}
                                    </tr>
                                  )}

                                  {getFieldData(fields, 'ctaUrl') &&
                                    getFieldData(fields, 'ctaTitle') && (
                                      <tr align="center">
                                        {CallToAction({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: {
                                            ctaUrl: getFieldData(
                                              fields,
                                              'ctaUrl',
                                            ),
                                            ctaTitle: getFieldData(
                                              fields,
                                              'ctaTitle',
                                            ),
                                          },
                                          style: { marginTop: '10px' },
                                        })}
                                      </tr>
                                    )}
                                </tbody>
                              </table>
                            )}
                          </td>
                          <td>
                            <table
                              cellPadding="0px"
                              cellSpacing="0"
                              border="0"
                              style={{
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      border="0"
                                      style={{
                                        paddingBottom: '8px',
                                      }}
                                    >
                                      <tbody>
                                        {getFieldData(fields, 'name') && (
                                          <tr>
                                            {Name({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'name',
                                              ),
                                              style: {
                                                fontWeight: 700,
                                                fontFamily: 'inherit',
                                                textTransform: 'uppercase',
                                              },
                                            })}
                                          </tr>
                                        )}

                                        {(getFieldData(fields, 'position') ||
                                          getFieldData(
                                            fields,
                                            'companyName',
                                          )) && (
                                          <tr>
                                            <td>
                                              <table
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                style={{
                                                  borderCollapse:
                                                    'collapse !important',
                                                  fontSize: 'inherit',
                                                }}
                                              >
                                                <tbody>
                                                  <tr valign="center">
                                                    {getFieldData(
                                                      fields,
                                                      'position',
                                                    ) &&
                                                      Position({
                                                        signature,
                                                        data: getFieldData(
                                                          fields,
                                                          'position',
                                                        ),
                                                        style: {
                                                          fontFamily: 'inherit',
                                                        },
                                                        tdProp: {
                                                          align: 'center',
                                                        },
                                                      })}

                                                    {getFieldData(
                                                      fields,
                                                      'position',
                                                    ) &&
                                                      getFieldData(
                                                        fields,
                                                        'companyName',
                                                      ) && (
                                                        <td>
                                                          <span
                                                            style={{
                                                              padding: '0 8px',
                                                              color: 'black',
                                                            }}
                                                          >
                                                            {' '}
                                                            |{' '}
                                                          </span>
                                                        </td>
                                                      )}

                                                    {getFieldData(
                                                      fields,
                                                      'companyName',
                                                    ) &&
                                                      CompanyName({
                                                        signature,
                                                        data: getFieldData(
                                                          fields,
                                                          'companyName',
                                                        ),
                                                        style: {
                                                          fontFamily: 'inherit',
                                                        },
                                                        tdProps: {
                                                          align: 'center',
                                                        },
                                                      })}
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        )}

                                        {getFieldData(fields, 'department') && (
                                          <tr>
                                            {Department({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'department',
                                              ),
                                              style: {
                                                fontFamily: 'inherit',
                                              },
                                            })}
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>

                                {getFieldData(fields, 'phone') && (
                                  <tr>
                                    {Phone({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'phone'),
                                      style: {},
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'email') && (
                                  <tr>
                                    {Email({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'email'),
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'website') && (
                                  <tr>
                                    {Website({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'website'),
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'companyAddress') && (
                                  <tr>
                                    {CompanyAddress({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyAddress',
                                      ),
                                      style: {},
                                    })}
                                  </tr>
                                )}

                                {!socialFields?.every(
                                  (obj) => obj?.value === '',
                                ) && (
                                  <tr>
                                    {SocialLinks({
                                      signature,
                                      showAwsIcons,
                                      data: socialFields,
                                      design,
                                      style: { paddingTop: '8px' },
                                    })}
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table>
                      <tr>
                        {getFieldData(fields, 'companyLogo') &&
                          CompanyLogo({
                            data: getFieldData(fields, 'companyLogo'),
                            design,
                            style: { textAlign: 'left', paddingTop: '10px' },
                          })}
                      </tr>
                      <tr>
                        {getFieldData(fields, 'ctaBannerImg') &&
                          getFieldData(fields, 'ctaBannerUrl') &&
                          CallToActionBanner({
                            data: {
                              ctaBannerUrl: getFieldData(
                                fields,
                                'ctaBannerUrl',
                              ),
                              ctaBannerImg: getFieldData(
                                fields,
                                'ctaBannerImg',
                              ),
                            },
                            design,
                            style: { textAlign: 'left', paddingTop: '10px' },
                          })}
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table width={tableWidth}>
                      <tr>
                        {getFieldData(fields, 'footer') &&
                          Footer({
                            data: getFieldData(fields, 'footer'),
                            design,
                          })}
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <Branding hideBranding={hideBranding} />
        </tr>
      </tbody>
    </table>
  );
}

export default TemplateStyleFourteen;
