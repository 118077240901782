import { useAppContext } from 'AppContext';
import { EllipsisHorizontalIcon } from 'assets/svg';
import SignatureTemplate from 'components/SignatureTemplate';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { cn } from 'lib/utils';
import React from 'react';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES } from 'common/constants';

const SignatureCard = ({
  signature = {},
  actions = {},
  count = 1,
  signatureCardClassname,
  signatureTemplateClassname,
  handleSignatureCardOnClick,
  hideAction,
}) => {
  const { setSignature, setHeadshot, handleSubscriptionModal } =
    useAppContext();
  const {
    onClick = () => {},
    handleEdit = () => {},
    handleDelete = () => {},
    handleDuplicate = () => {},
  } = actions;
  const { isFreeUser } = useUserPermission();

  const { isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();
  const items = [
    {
      onClick: () => {
        handleEdit(signature);
      },
      label: <span>Edit</span>,
      key: '0',
      hide: false,
    },
    {
      onClick: () => {
        if (isFreeUser) {
          handleSubscriptionModal(true);
          return; // Exit if the user needs to manage subscriptions
        }
        handleDuplicate(signature);
      },
      label: <span>Duplicate</span>,
      key: '1',
      hide: false,
    },
    {
      onClick: () => {
        handleDelete(signature);
      },
      label: <span>Delete</span>,
      key: '3',
      hide: count === 1,
    },
  ];

  const handleOnClick = (signature) => {
    if (!handleSignatureCardOnClick) {
      setSignature(null);
      setHeadshot(null);
      onClick(signature?.id);
    } else {
      handleSignatureCardOnClick(signature);
    }
  };

  const hideBranding =
    isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
    role === ROLES.TEAMMATE ||
    role === ROLES.MANAGER;

  return (
    <div
      onClick={() => handleOnClick(signature)}
      className={cn(
        `bg-white-0 relative flex justify-start
max-w-[350px] min-w-[350px] h-[220px] rounded-[8px] overflow-hidden shadow-lg cursor-pointer m-2
bg-white border-[1px] border-solid border-secondary-400 hover:border-solid hover:border-[1px]  hover:border-primary hover:shadow-signature`,
        signatureCardClassname,
      )}
    >
      <div className="w-full ">
        <div
          className={cn(
            'w-full transform scale-[0.7] translate-x-[-40px] pointer-events-none',
            signatureTemplateClassname,
          )}
        >
          {signature && (
            <SignatureTemplate
              signature={signature}
              showAwsIcons
              hideBranding={hideBranding}
            />
          )}
        </div>
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className="absolute bottom-0 w-full box-border flex justify-between items-center px-3 py-2 bg-primary-foreground"
      >
        <div>{signature?.name}</div>
        {!hideAction && (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <EllipsisHorizontalIcon />
              </DropdownMenuTrigger>
              <DropdownMenuContent className=" bg-white-0 border-solid border-1 border-gray-400 ">
                {items.map(({ label, key, onClick, hide = false }) => {
                  if (hide) {
                    return;
                  }
                  return (
                    <DropdownMenuItem
                      key={key}
                      value={key}
                      onClick={onClick}
                      className="w-[auto]"
                    >
                      {label}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignatureCard;
