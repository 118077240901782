import { get } from 'lodash';
import { getFieldData, getLiveUrl } from 'common/utils';
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import CompanyAddress from './components/CompanyAddress';
import Website from './components/Website';
import CompanyLogo from './components/CompanyLogo';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleSix({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const ctadivider =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl') &&
    !getFieldData(fields, 'footer');

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        margin: '0 !important',
        padding: '0 !important',
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tr>
        <td aria-label="td">
          <table width="max-content" ref={mainContentRef}>
            <tr>
              <td>
                <table
                  width="100%"
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                  role="presentation"
                  style={{
                    borderCollapse: 'collapse !important',
                    fontSize: 'inherit',
                  }}
                >
                  <tr>
                    <td
                      style={{
                        fontStyle: 'normal',
                      }}
                      valign="bottom"
                    >
                      <table>
                        {getFieldData(fields, 'name') && (
                          <tr>
                            {Name({
                              signature,
                              data: getFieldData(fields, 'name'),
                            })}
                          </tr>
                        )}

                        {getFieldData(fields, 'position') && (
                          <tr>
                            {Position({
                              signature,
                              data: getFieldData(fields, 'position'),
                            })}
                          </tr>
                        )}
                        {(getFieldData(fields, 'department') ||
                          getFieldData(fields, 'companyName')) && (
                          <tr>
                            {getFieldData(fields, 'department') &&
                              Department({
                                signature,
                                data: getFieldData(fields, 'department'),
                              })}
                            {getFieldData(fields, 'companyName') &&
                              CompanyName({
                                signature,
                                data: getFieldData(fields, 'companyName'),
                              })}
                          </tr>
                        )}
                      </table>
                    </td>
                    {getFieldData(fields, 'headshotUrl') &&
                      HeadshotImage({
                        data: getFieldData(fields, 'headshotUrl'),
                        design,
                        align: 'right',
                      })}
                  </tr>
                </table>
              </td>
            </tr>
            {!allUrlsAreNull && (
              <tr>
                <Divider signature={signature} />
              </tr>
            )}
            {!allUrlsAreNull && (
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    role="presentation"
                    width="100%"
                    style={{
                      borderCollapse: 'collapse !important',
                      fontSize: 'inherit',
                    }}
                  >
                    {(getFieldData(fields, 'phone') ||
                      getFieldData(fields, 'email')) && (
                      <tr>
                        {getFieldData(fields, 'phone') &&
                          Phone({
                            signature,
                            showAwsIcons,
                            design,
                            data: getFieldData(fields, 'phone'),
                          })}
                        {getFieldData(fields, 'email') &&
                          Email({
                            signature,
                            showAwsIcons,
                            design,
                            data: getFieldData(fields, 'email'),
                            tdProps: { align: 'right' },
                          })}
                      </tr>
                    )}

                    {(getFieldData(fields, 'companyAddress') ||
                      getFieldData(fields, 'website')) && (
                      <tr>
                        {getFieldData(fields, 'companyAddress') &&
                          CompanyAddress({
                            signature,
                            showAwsIcons,
                            design,
                            data: getFieldData(fields, 'companyAddress'),
                          })}
                        {getFieldData(fields, 'website') &&
                          Website({
                            signature,
                            showAwsIcons,
                            design,
                            data: getFieldData(fields, 'website'),
                            tdProps: { align: 'right' },
                          })}
                      </tr>
                    )}
                  </table>
                </td>
              </tr>
            )}
          </table>
        </td>
      </tr>
      {!ctadivider && (
        <tr>
          <Divider signature={signature} />
        </tr>
      )}
      <tr>
        <td style={{ paddingTop: '8px' }}>
          <table
            cellPadding="0px"
            cellSpacing="0"
            border="0"
            role="presentation"
            style={{
              borderSpacing: '0',
            }}
          >
            <tr>
              {getFieldData(fields, 'ctaUrl') &&
                getFieldData(fields, 'ctaTitle') &&
                CallToAction({
                  signature,
                  showAwsIcons,
                  design,
                  data: {
                    ctaUrl: getFieldData(fields, 'ctaUrl'),
                    ctaTitle: getFieldData(fields, 'ctaTitle'),
                  },
                  style: {
                    paddingBottom: '8px',
                  },
                })}
              {!!socialFields?.length &&
                SocialLinks({
                  signature,
                  showAwsIcons,
                  data: socialFields,
                  design,
                  tdProps: {
                    align: 'right',
                    valign: 'center',
                  },
                  style: {
                    paddingBottom: '8px',
                  },
                })}
            </tr>
          </table>
          {getFieldData(fields, 'ctaBannerImg') && (
            <table width={tableWidth} ref={extraContentRef}>
              <tr>
                {getFieldData(fields, 'ctaBannerImg') &&
                  getFieldData(fields, 'ctaBannerUrl') &&
                  CallToActionBanner({
                    data: {
                      ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                      ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                    },
                    design,
                    style: { textAlign: 'left', paddingTop: '8px' },
                  })}
              </tr>
            </table>
          )}
          {(getFieldData(fields, 'footer') ||
            getFieldData(fields, 'companyLogo')) && (
            <table width={tableWidth}>
              {getFieldData(fields, 'companyLogo') && (
                <tr align="center">
                  {CompanyLogo({
                    data: getFieldData(fields, 'companyLogo'),
                    design,
                    style: {
                      marginTop: '8px',
                    },
                  })}
                </tr>
              )}
              <tr>
                {Footer({
                  data: getFieldData(fields, 'footer'),
                  design,
                  style: {
                    color: '#7A7289',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '12px',
                  },
                })}
              </tr>
            </table>
          )}
          <Branding hideBranding={hideBranding} />
        </td>
      </tr>
    </table>
  );
}

export default TemplateStyleSix;
