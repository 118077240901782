import React from 'react';
import { get } from 'lodash';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { getFieldData, getLiveUrl } from 'common/utils';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleFive({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef } = useTableWidth(downloadUrl);
  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');

  return (
    <table
      width={tableWidth}
      style={{
        margin: '0 !important',
        padding: '0 !important',
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tr>
        <td>
          <table
            ref={mainContentRef}
            cellPadding="0"
            cellSpacing="0"
            border="0"
            role="presentation"
            style={{
              borderCollapse: 'collapse !important',
              fontSize: 'inherit',
              paddingBottom: '8px',
            }}
          >
            <tr
              style={{
                height: '100%',
              }}
            >
              <td
                style={{
                  lineHeight: '0px',
                  display: 'flex',
                }}
              >
                {(getFieldData(fields, 'headshotUrl') ||
                  getFieldData(fields, 'companyLogo')) && (
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    role="presentation"
                    style={{
                      borderCollapse: 'collapse !important',
                      fontSize: 'inherit',
                      paddingRight: '14px',
                    }}
                  >
                    {getFieldData(fields, 'headshotUrl') && (
                      <tr align="center">
                        {HeadshotImage({
                          data: getFieldData(fields, 'headshotUrl'),
                          design,
                          style: {
                            height: '50%',
                            justifyContent: 'center',
                            paddingTop: '23px',
                            paddingBottom: '8px',
                          },
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'companyLogo') && (
                      <tr align="center">
                        {CompanyLogo({
                          data: getFieldData(fields, 'companyLogo'),
                          design,
                          style: {
                            marginTop: '8px',
                          },
                        })}
                      </tr>
                    )}
                  </table>
                )}
              </td>
              {!allUrlsAreNull && (
                <td
                  style={{
                    padding: '0 0 0 14px',
                    borderLeft: `1px solid ${design?.primaryBrandColor}`,
                  }}
                >
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    role="presentation"
                    style={{
                      borderCollapse: 'collapse !important',
                      fontSize: 'inherit',
                    }}
                  >
                    {getFieldData(fields, 'name') && (
                      <tr>
                        {Name({
                          signature,
                          data: getFieldData(fields, 'name'),
                          style: {
                            fontWeight: 700,
                            color: design?.primaryBrandColor,
                          },
                        })}
                      </tr>
                    )}

                    {getFieldData(fields, 'position') && (
                      <tr>
                        {Position({
                          signature,
                          data: getFieldData(fields, 'position'),
                        })}
                      </tr>
                    )}
                    <tr>
                      <td>
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          role="presentation"
                          style={{
                            borderCollapse: 'collapse !important',
                            fontSize: 'inherit',
                          }}
                        >
                          {getFieldData(fields, 'department') && (
                            <tr>
                              {Department({
                                signature,
                                data: getFieldData(fields, 'department'),
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyName') && (
                            <tr>
                              {CompanyName({
                                signature,
                                data: getFieldData(fields, 'companyName'),
                              })}
                            </tr>
                          )}
                          {!allUrlsAreNull && (
                            <tr>
                              <Divider
                                signature={signature}
                                style={{ marginBottom: 8, marginTop: 8 }}
                              />
                            </tr>
                          )}
                          {!!socialFields?.length && (
                            <tr>
                              <td style={{ paddingBottom: '4px' }}>
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                >
                                  {!socialFields?.every(
                                    (obj) => obj?.value === '',
                                  ) && (
                                    <tr>
                                      {SocialLinks({
                                        signature,
                                        showAwsIcons,
                                        data: socialFields,
                                        design,
                                      })}
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                          )}

                          <tr>
                            <td>
                              <table
                                cellPadding="0px"
                                cellSpacing="0"
                                border="0"
                              >
                                {getFieldData(fields, 'phone') && (
                                  <tr>
                                    {Phone({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'phone'),
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'email') && (
                                  <tr>
                                    {Email({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'email'),
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'website') && (
                                  <tr>
                                    {Website({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'website'),
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'companyAddress') && (
                                  <tr>
                                    {CompanyAddress({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyAddress',
                                      ),
                                    })}
                                  </tr>
                                )}
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              )}
            </tr>
          </table>
          {!ctasection && (
            <table>
              <tr>
                <td>
                  {getFieldData(fields, 'ctaUrl') &&
                    getFieldData(fields, 'ctaTitle') && (
                      <table>
                        <tr>
                          {CallToAction({
                            signature,
                            showAwsIcons,
                            design,
                            data: {
                              ctaUrl: getFieldData(fields, 'ctaUrl'),
                              ctaTitle: getFieldData(fields, 'ctaTitle'),
                            },
                          })}
                        </tr>
                      </table>
                    )}
                </td>
              </tr>
              <tr>
                {getFieldData(fields, 'ctaBannerImg') &&
                  getFieldData(fields, 'ctaBannerUrl') &&
                  CallToActionBanner({
                    data: {
                      ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                      ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                    },
                    design,
                    style: {
                      textAlign: 'left',
                      paddingTop: '8px',
                    },
                  })}
              </tr>
            </table>
          )}

          <table>
            <tr>
              {getFieldData(fields, 'footer') &&
                Footer({
                  data: getFieldData(fields, 'footer'),
                  design,
                })}
            </tr>
          </table>
        </td>
      </tr>

      <Branding hideBranding={hideBranding} />
    </table>
  );
}

export default TemplateStyleFive;
